<template>
  <div id="Invoice" class="invoice_box">
        <div class="invoice_title">		{{ (userInfo.shop && userInfo.shop.title) || '店铺名称' }}</div>
        <div class="invoice_tips">{{invoiceInfo.name || '收据名称'}}</div>
        <div class="invoice_info">
            <div class="invoice_info_text">
                {{ invoiceInfo.invoiceTime }}
            </div>
            <div class="invoice_info_text" style="justify-content: flex-end;">
                收据编号：{{ invoiceInfo.invoiceNo  }}
            </div>
        </div>  
        <div class="table_box">
                <div class="table_tr">
                    <div class="table_title">交款单位:</div>
                    <div class="invoice_input wid40">{{ invoiceInfo.rerson  }}</div>
                    收款方式：<div class="invoice_input flex1">{{ invoiceInfo.paymentType }}</div>
                </div>
                <div class="table_tr">
                    <div class="table_title">人民币(大写):</div>
                    <div  class="invoice_input wid40">{{initConvertToChinese}}</div>
                    ￥<div  class="invoice_input flex1">{{ invoiceInfo.price }}</div>
                </div>
                <div class="table_tr">
                    <div class="table_title">收款事由：</div>
                    <div class="table_td">
                        <div  class="invoice_input wid30">今收{{ invoiceInfo.rerson }}</div>
                        <div  class="invoice_input flex1">{{ invoiceInfo.diningDate }}{{ invoiceInfo.orderType }}</div>
                        <div  class="invoice_input flex1" style="text-align: right;">{{ invoiceInfo.diningTime }}就餐</div>
                    </div>
                </div>
                <div class="table_tr padding110">
                    <div  class="invoice_input wid30">{{ invoiceInfo.with_roomTitle }}</div>
                    <div  class="invoice_input flex1">定金{{ invoiceInfo.price }}元</div>
                </div>
                <!-- <div class="table_tr padding110">
                    <div  class="invoice_input flex1">{{ invoiceInfo.remarks }}</div>
                </div> -->
                <div class="table_tr padding110">
                    {{ invoiceInfo.notes || '收据说明'}}
                </div>
        </div>
        <div class="invoice_info">
            <div class="invoice_info_text">
               会计：{{ invoiceInfo.accountant }}
            </div>
            <div class="invoice_info_text" style="justify-content: center;">
               收款人：{{ invoiceInfo.salesName }}
            </div>
            <div class="invoice_info_text" style="justify-content: flex-end;">
               交款人：{{ invoiceInfo.rerson  }}
            </div>
        </div>
        <div class="invoice_number" v-if="invoiceInfo.barcodeNumber">
            <canvas ref="barcodeCanvas"></canvas>
        </div>       
  </div>
</template>

<script>
import JsBarcode from 'jsbarcode';
import { mapState } from 'vuex'
import moment from 'moment'
import {convertToChinese} from '@/utils/utils'
export default {
  name: 'Invoice',
  data() {
    return {}
  },
  props:{
	invoiceInfo: {
	    type: Object,
		default: () => ({ 
            name:'订餐定金专用收据(凭单消费)',
            notes:'此定金专用收据仅限当餐抵用、遗失不补、违约不退、过期作废',
            invoiceTime:moment().format('YYYY年MM月DD日'),
            diningDate:moment().format('YYYY年MM月DD日'),
            diningTime:moment().format('HH点mm分'),
            invoiceNo:'XXXXXXX',
            orderType:'午餐',
            price:100,
            with_roomTitle:'预订餐位',
            remarks:'无',
            accountant:'会计',
            rerson:'预订客户',
            salesName:'销售',
            paymentType:'支付宝',
            barcodeNumber:''
        })
	},
  },
  mounted() {
  },
  watch: {
    invoiceInfo: {
      immediate: true,
      handler: function () {
        const barcodeNumber = this.invoiceInfo.barcodeNumber;// 这里是条形码编号，你可以替换成实际的编号
        if(barcodeNumber){
            this.$nextTick(()=>{
                const canvas = this.$refs.barcodeCanvas;
                JsBarcode(canvas, barcodeNumber, {
                    height: 50,
                    width: 2,
                    format: "CODE128",// 条形码格式，可根据需要调整，常见的有CODE128、EAN13等
                    lineColor: "#000"
                });
                
            })
        }
      },
    },
  },
  computed: {
    ...mapState('user', {userInfo: 'info'}),
    initConvertToChinese() {
        return convertToChinese(this.invoiceInfo.price)
    }
  },
}
</script>

<style scoped lang="less">
.invoice_box{
    color: #333333;
    font-weight: 500;
    .table_tr,.table_td{
        display: flex;
        align-items: center;
    }
    .table_tr{
        width: 100%;
        flex: 1;
        padding: 10px 0;
    }
    .table_title{
        min-width: 110px;
        text-align: left;
    }
    .padding110{
        padding-left: 110px;
        box-sizing: border-box;
    }
}
.invoice_title{
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}
.invoice_info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .invoice_info_text{
        padding: 10px;
        flex: 1;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }
}
.invoice_tips{
    font-size: 20px;
    text-align: center;
    font-weight: bold;
}
.table_box{
    border: 1px solid #333333;
    padding: 10px;
    display: flex;
    flex-direction: column;
}
.invoice_input{
    border:none;
    border-radius: 0%;
    border-bottom: 1px solid #333333;
    border-bottom-style: dashed;
}
.table_td{
    border-bottom: 1px solid #333333;
    border-bottom-style: dashed;
    flex: 1;
    .invoice_input{
        border:none;
        border-radius: 0%;
    }
}
.wid40{
    width: 40%;
}
.wid50{
    width: 50%;
}
.wid30{
    width: 30%;
}
.flex1{
    flex: 1;
    flex-shrink: 0;
    white-space: nowrap;
}
</style>
